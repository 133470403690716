.staffMainWrapper {
  display: flex;
  /* padding-bottom: 50px;
  margin-top: -22px; */
  width: 100%;
}

.staffRouterDiv {
  display: flex;
}

.staffListWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 230px);
}
.staffListWrapper:nth-child(1) {
  border-top: 1px solid #ededed;
}

.staffCard {
  min-width: 100%;
  max-width: 275px;
  display: block;
  position: relative;
  overflow: hidden;
  background: #ffffff;
  z-index: 4;
  text-decoration: none;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  border: 1px solid rgba(222, 222, 222, 0.7);
  cursor: pointer;
}
.selectedStaffCard {
  background: #f8f8f8;
  border-left: 2px solid #11c89b;
}
.NoResult {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #142c44;
}
.TryAnother {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #667085;
}

.staffCardImg {
  width: 47px;
  height: 47px;
}

.staffCardDetails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.staffStatus svg {
  font-size: 18px;
}

.staffStatus > div {
  display: flex;
}

.statusActive {
  color: #23a67b;
}

.statusInActive {
  color: #d32f2f;
}

.staffName {
  font-weight: 500;
  font-size: 12px;
  color: #142c44;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
  font-family: "Poppins";
  margin-top: 5px;
}

.staffNameDetail {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #142c44;
  text-transform: capitalize;
}
.staffRoleDetail {
  font-weight: 500;
  font-size: 12px;
  line-height: 25px;
  color: #667085;
  text-transform: capitalize;
}
.key {
  font-weight: 400;
  font-size: 12px;
  color: #667085;
  text-transform: capitalize;
}
.value {
  font-weight: 500;
  font-size: 12px;
  color: #142c44;
}
.staffDesignation {
  font-weight: 400;
  font-size: 11px;
  color: #667085;
  font-family: "Poppins";
  text-transform: capitalize;
}

.staffId,
.staffMail,
.staffPhone {
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  color: #667085;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-family: "Poppins";
}

.staffContactWrap {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.staffContactWrap li {
  font-weight: 400;
  font-size: 12px;
  color: #c7c7c7;
}

.staffRoleWrap {
  color: #c7c7c7;
}

.leftSection {
  width: 32%;
  border-right: 1px solid #ededed;
  height: 100vh;
  /* padding-top: 22px; */
}
.rightSection {
  padding: 40px 20px 20px 20px;
  width: calc(100% - 32%);
  overflow-y: auto;
  height: calc(100vh - 114px);
  display: flex;
  flex-direction: column;
}
.rightSubSection {
  max-width: 780px;
  min-width: 90%;
  border: 1px solid #ebebed;
  border-radius: 6px;
  margin-left: 20px;
  margin-top: 20px;
}

.staffEditorWrapper {
  max-width: 800px;
  padding-top: 22px;
}
.nameInputWrapper {
  display: flex;
  gap: -10px;
  justify-content: space-between;
}

.link {
  text-decoration: none;
  padding-right: 10px;
}

.BtnsaveStaff {
  margin-bottom: 20px !important;
}

.editBtn {
  background: #98989833;
  color: #142c44;
  margin-bottom: 5px;
  border: 1px solid #98989833;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  height: fit-content;
}

.addHeadSplit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 40%;
  margin-bottom: 30px;
  position: relative;
}

.branchSaveButton {
  float: right;
  margin-top: 40px;
  margin-bottom: 80px;
}

.staffIdLabel {
  font-weight: 400;
  font-size: 12px;
  color: #3f3e43;
  margin-right: 5px;
  background-color: red;
}

.staffIdWrap {
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  color: #667085;
  display: flex;
  font-family: "Poppins";
}

.staffAvatar {
  aspect-ratio: 1;
  text-transform: capitalize;
}

.formTitle {
  font-weight: 600;
  font-size: 20px;
  color: #3f3e43;
  margin-bottom: 0;
  margin-top: 0;
}

.courseListFilterWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
}

.courseListStatusFilterWrap {
  display: flex;
  align-items: center;
  gap: 20px;
}

.courseListStatusFilterItem {
  font-weight: 600;
  font-size: 14px;
  color: #3f3e43;
  text-decoration: none;
  padding: 5px 0;
  border: none;
  background: transparent;
  cursor: pointer;
}

.FilterBtnactive {
  color: #23a67b;
}

.NoBatch {
  text-align: center;
  display: flex;
  justify-content: center;
}

.courseListSelectFilterWrap {
  display: flex;
  align-items: center;
}

.cancelFormPopupTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #101828;
  margin-bottom: 8px;
  font-family: "poppins";
}
.cancelFormPopupContent {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  margin-bottom: 15px;
  font-family: "poppins";
}
.cancelFormPopupBtnsSplit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cancelFormPopupNoBtn {
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  min-width: 160px;
  height: 35px;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #344054;
  padding: 8px 10px;
  background: #fff;
  text-transform: capitalize;
  cursor: pointer;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}
.cancelFormPopupYesBtn {
  background: #ec3939;
  border-radius: 4px;
  min-width: 160px;
  height: 35px;
  padding: 8px 10px;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  border: 2px solid #ec3939;
  text-transform: capitalize;
  cursor: pointer;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
}
.PageBackBtn {
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: #667085;
  padding-left: 0;
  cursor: pointer;
  margin-bottom: 20px;
}
.reactivateBtn {
  border-radius: 4px;
  border: 1px solid #11c89b;
  background: #fff;
  padding: 4px 14px;
  color: #11c89b;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  outline: none;
  max-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.reactivatePopupTitle {
  color: #101828;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 10px;
}
.staffStatusDeactivated {
  color: #e7453a;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-transform: capitalize;
  border-radius: 16px;
  background: #fef3f2;
  padding: 2px 8px;
}
.staffNameWrap {
  display: flex;
  gap: 8px;
  align-items: center;
}
.teamHead {
  color: #142c44;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 500;
}

@media (min-width: 50px) and (max-width: 1024px) {
  .courseListFilterWrap {
    display: block;
  }

  .courseListSelectFilterWrap {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 900px) {
  .staffMainWrapper {
    border-top: 1px solid #ededed;
  }
}

@media only screen and (max-width: 1024px) {
  .link {
    padding-right: 6px;
  }
  .leftSection {
    width: 40%;
  }
  .teamHead {
    font-size: 18px;
  }
}

.staffNameAndDeleteContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
