.modalTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #142c44;
}
.modalTitleWrap {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 26px;
}
.closeModalButton {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.buttonSection {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 8px;
}
.title {
  font-weight: 600;
  margin-bottom: 16px;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 360px;
  /* height: 500px; */
  gap: 20px;
  flex-wrap: wrap;
}

.reportButton {
  margin: 0;
  border: 1px solid #bfbfbf;
  background-color: #fff;
  color: #555555;
  transition: background-color 0.3s, border 0.3s;
  height: 125px;
  /* width: 200px; */
  width: 170px;
  max-width: calc(50% - 10px);
}

.reportButton:hover {
  background-color: #e8faf5;
}

/* .icon {
    margin-right: 8px; 
  } */
.MuiDialog-paper {
  position: relative;
  z-index: 99999;
  max-width: 400px;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
