.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
}
.accordionCOntainer {
  padding: 10px;
  border: 1px solid #f4f4f4;
  /* min-height: 200px; */
  /* overflow-y: auto; */
}
.accordionTitle {
  font-size: 12px;
  color: #667085;
  font-weight: 400;
}
.accordionDetailsContainer {
  /* padding: 5px; */
}
.appliedFilter {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background-color: rgba(17, 200, 155, 0.06);
  text-align: center;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #11c89b;
}
.countContainer {
  display: flex;
  align-items: center;
}
.accordionSingleSelectValue {
  color: #667085;
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
}
.accordionHeaderLeft {
  display: flex;
  align-items: center;
  gap: 5px;
}
