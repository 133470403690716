.modalTitle {
  color: #142c44;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
}
.closeModalButton {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: -24px;
}
.buttonSection {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 8px;
}
